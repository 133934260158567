/**
 * Created by sanchez
 */
'use strict';

//check the environment
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!')
}

/****** IMPORT MODULES AND LIBRAIRIES *******/

// import CSS
// import animate_css from 'animate.css/animate.min.css';
//import css from '../styles/css.css';
import scss from '../styles/sass.scss'

import $ from 'jquery'

window.jQuery = $;
window.$ = $;

import 'bootstrap'

import 'jquery-match-height'
import Masonry from 'masonry-layout';

import 'slick-carousel'
import '../../../node_modules/slick-carousel/slick/slick-theme.scss'
import '../../../node_modules/slick-carousel/slick/slick.scss'

import SteppedDatePicker from './Library/SteppedDatePicker'
import ActivityTheme from './components/ActivityTheme'
import SwitchInputCheckbox from './components/SwitchInputCheckbox'
import CourseAccommodationCheckbox from './components/CourseAccommodationCheckbox'
import CourseCategory from './components/CourseCategory'

import '../../../node_modules/nouislider/distribute/nouislider.css'
import 'nouislider'
import wNumb from 'wnumb'
import moment from 'moment'

moment.locale('fr');
// ISO-8601, Europe
moment.updateLocale('fr', {
  week: {
    dow: 1, // First day of week is Monday
    doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
  }
});

/****** READY SCRIPTS *******/
// window.onload = function () {
//     carousel.init()
//     matchHeight.init()
// };

$(document).ready(function () {
  carousel.init();
  matchHeight.init();
  window.matchHeight = matchHeight;
  grid_masonry.init();
  tarif.change_block()

  new SteppedDatePicker();
  new ActivityTheme();
  window.SwitchInputCheckbox = new SwitchInputCheckbox();
  window.CourseAccommodationCheckbox = new CourseAccommodationCheckbox();
  new CourseCategory();

  // main_header.sticky()
  // product.search_fixed()
  // product.search_slide()
  // product.section_view()
  // product.get_date()
  //reserve.legend()
  // reserve.stage()
  //reserve.selected_form()
  // slider.price()
  //slider.other_dest()

  $(window).on('load', function () {
    carousel.init();
    matchHeight.init();
    header_height.header_body()
    // product.filter_month_date(1)
    // product.filter_month_date(2)
  });
  $(window).on('resize', function () {
    carousel.init();
    matchHeight.init();
    header_height.header_body();
    grid_masonry.init()
  })
});

let util = {
  scrollToTop() {
    let $body = $('html, body');

    let target = $('div').offset().top;
    $body.animate({'scrollTop': target}, 750)
  },
  scrollTopClick($link) {
    /*-- give a link the ability of scrolling to an anchor --*/
    let $body = $('html, body');
    if ($link.length) {

      $link.on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let target = $($this.attr('href')).offset().top;
        $body.animate({'scrollTop': target}, 750)
      })
      /*-- give a link the ability of scrolling to an anchor --*/

    }
  },
  onlyUniqueArray(value, index, self) {
    return self.indexOf(value) === index
  }
};

let tarif = {
  change_block() {
    let btn_selector = $('.p-tarif .block-select .item')

    btn_selector.on('click', function(e) {
      e.preventDefault();

      let block_num = $(this).attr('data-block')

        if (block_num) {
            let block_tarif = $('.page-tarif-block');
            block_tarif.removeClass('active');
            btn_selector.removeClass('active')
            $('.page-tarif-block[data-block="' + block_num + '"]').addClass('active')
            $('.p-tarif .block-select .item[data-block="' + block_num + '"]').addClass('active')
        }
    })
  }
}

/****** HOME CAROUSEL *******/

let carousel = {
  add_carousel(el, width, opt = []) {
    if (el !== 'undefined') {

      opt['dots'] = true;
      opt['infinite'] = true;
      opt['speed'] = 800;
      opt['slidesToShow'] = 1;

      if (el.hasClass('finite')) {
        opt['infinite'] = false;
      }

      if (el.hasClass('no-center')) {
        opt['centerMode'] = false;
      }

      if (el.hasClass('adp-h')) {
        // console.log('slick H')
        opt['centerMode'] = true;
        opt['adaptiveHeight'] = true
      } else if (el.hasClass('adp-w')) {
        // console.log('slick W')
        opt['centerMode'] = true;
        opt['variableWidth'] = true
      }

      if (el.hasClass('adp-md-2')) {
        opt['slidesToShow'] = 2;
        opt['responsive'] = [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1
            }
          }
        ]
      }

      if (el.hasClass('sj-slide-4s')) {
        opt['autoplaySpeed'] = 4000
      }
      if (el.hasClass('sj-slide-5s')) {
        opt['autoplaySpeed'] = 5000
      }
      if (el.hasClass('sj-slide-6s')) {
        opt['autoplaySpeed'] = 6000
      }
      if (el.hasClass('sj-slide-8s')) {
        opt['autoplaySpeed'] = 8000
      }
      if (el.hasClass('sj-slide-10s')) {
        opt['autoplaySpeed'] = 10000
      }
      if (el.hasClass('sj-slide-auto')) {
        opt['autoplay'] = true;
        opt['autoplaySpeed'] = 3000
      }

      if (el.hasClass('sj-slide-fade')) {
        opt['fade'] = true;
        opt['cssEase'] = 'ease-in-out'
      }

      if (el.hasClass('no-dots')) {
        opt['dots'] = false
      }

      if (el.hasClass('slide-xl-5')) {
        // console.log('slidexl4 active')
        if (width > 992) {
          opt['slidesToShow'] = 5;
          opt['slidesToScroll'] = 1
        }
        opt['responsive'] = [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 5,
              slidesToScroll: 1,
              dots: true
            }
          },
          {
            breakpoint: 765,
            settings: {
              arrows: false,
              slidesToShow: 4,
              slidesToScroll: 2,
              dots: true
            }
          },
          {
            breakpoint: 576,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]
      }

      if (el.hasClass('slide-xl-4')) {
        // console.log('slidexl4 active')
        if (width > 992) {
          opt['slidesToShow'] = 4;
          opt['slidesToScroll'] = 2
        }
        opt['responsive'] = [
          {
            breakpoint: 1280,
            settings: {
              arrows: false,
              slidesToShow: 4,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 765,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
      if (el.hasClass('slide-xl-3')) {
        if (width > 992) {
          opt['slidesToShow'] = 3;
          opt['slidesToScroll'] = 2
        }
        opt['responsive'] = [
          {
            breakpoint: 1280,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 765,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
      if (el.hasClass('slide-lg-4') && width < 992) {
        opt['slidesToShow'] = 4;
        opt['slidesToScroll'] = 4;
        opt['responsive'] = [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 4
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              arrows: false,
              slidesToShow: 1
            }
          }
        ]
      }
      if (el.hasClass('slide-lg-3') && width < 992) {
        opt['slidesToShow'] = 3;
        opt['slidesToScroll'] = 3
      }
      if (el.hasClass('slide-lg-2') && width < 992) {
        opt['slidesToShow'] = 2;
        opt['slidesToScroll'] = 2
      }
      if (el.hasClass('slide-md-3') && width < 768) {
        opt['slidesToShow'] = 3;
        opt['slidesToScroll'] = 3
      }
      if (el.hasClass('slide-md-2') && width < 768) {
        opt['slidesToShow'] = 2;
        opt['slidesToScroll'] = 2
      }
      if (el.hasClass('slide-sm-2') && width < 576) {
        opt['slidesToShow'] = 2;
        opt['slidesToScroll'] = 2

      }

      // console.log(width, opt)
      el.slick({...opt})
    }
  },
  rm_carousel
    (el, width) {
    if (el !== 'undefined') {
      let slick = el.slick('getSlick');

      if (slick) {
        el.slick('unslick')
      }
    }
  }
  ,
  do_carousel(el, width) {
    let _el = {},
      diff = 0;

    if (!el.hasClass('slick-initialized')) {
      if (width < 576 - diff && el.hasClass('sj-slide-sm')) {
        _el = el;
        carousel.add_carousel(_el, width)
        // console.log('slick-sm')
      } else if (width < 768 - diff && el.hasClass('sj-slide-md')) {
        _el = $(document).find('.sj-slide.sj-slide-md');
        carousel.add_carousel(_el, width)
        // console.log('slick-md')
      } else if (width < 992 - diff && el.hasClass('sj-slide-lg')) {
        _el = $(document).find('.sj-slide.sj-slide-lg');
        carousel.add_carousel(_el, width)
        // console.log('slick-lg')
      } else if (width > 992 - diff && el.hasClass('sj-slide-xl')) {
        _el = $(document).find('.sj-slide.sj-slide-xl');
        carousel.add_carousel(_el, width)
        // console.log('slick-xl')
      } else {
        if (el.hasClass('sj-slide-full')) {
          carousel.add_carousel(el, width)
        }
      }
    } else {
      if (width > 576 - diff && el.hasClass('sj-slide-sm')) {
        _el = el;
        carousel.rm_carousel(_el, width)
        // console.log('remove slick-sm')
      } else if (width > 768 - diff && el.hasClass('sj-slide-md')) {
        _el = $(document).find('.sj-slide.sj-slide-md');
        carousel.rm_carousel(_el, width)
        // console.log('remove slick-md')
      } else if (width > 992 - diff && el.hasClass('sj-slide-lg')) {
        _el = $(document).find('.sj-slide.sj-slide-lg');
        carousel.rm_carousel(_el, width)
        // console.log('remove slick-lg')
      } else if (width > 1280 - diff && el.hasClass('sj-slide-xl')) {
        _el = $(document).find('.sj-slide.sj-slide-xl');
        carousel.rm_carousel(_el, width)
        // console.log('remove slick-xl')
      }
    }
  }
  ,
  init() {
    let h_carousel = $(document).find('.sj-slide'),
      h_c_nb = h_carousel.length,
      w_w = $(window).width();

    if (h_c_nb) {
      h_carousel.each(function (i, el) {
        carousel.do_carousel($(el), w_w)
      })
    }
  }
};

/****** FIX HEIGHT HEADER *******/

let header_height = {
  header_body() {
    let w_w = $(window).width();
    let header = $(document).find('.header');
    let body = $(document).find('.header .header_body');

    if (w_w <= 767.98) {

      header.css('height', '100vh');

      setTimeout(() => {
        let head = $(document).find('.header .header_head'),
          footer = $(document).find('.header .header_footer'),
          header = $(document).find('.header');

        let h_body = header.outerHeight() - (head.outerHeight() + footer.outerHeight());
        body.css('height', h_body + 'px')
      }, 100)
    } else {
      header.removeAttr('style');
      body.removeAttr('style')
    }
  }
};

/****** STICKY HEADER *******/

let main_header = {
  sticky() {
    let lastScrollTop = 0;

    $(window).scroll(function (event) {
      const st = $(this).scrollTop();
      const header = $('#main-header');
      const headerPos = $('#main-header').scrollTop();
      const bg_home = $('.bg-home');

      if (st > lastScrollTop) {
        //scroll down
        if (st >= 75) {
          header.addClass('hidden');
          header.addClass('header--sticky');
          bg_home.addClass('bg-home__mini')
          //header.removeClass('sticky');
        }
      } else {
        //scroll up
        if (st <= 10 && header.hasClass('header--sticky')) {
          header.removeClass('header--sticky');
          bg_home.removeClass('bg-home__mini')
        } else if (st > 10) {
          header.addClass('header--sticky');
          bg_home.addClass('bg-home__mini')
        }
        header.removeClass('hidden')
      }

      lastScrollTop = st
    })
  }
};

/****** PRODUCT JS *******/

let product = {
  search_fixed() {
    let img = $('.p-search__f-img');
    let p_search = $('.p-search .p-search-filter');

    if (img.length > 0) {
      $(window).on('scroll', function () {
        let t_img = img.offset().top,
          t_window = $(window).scrollTop();

        //console.log(t_window, "-",t_img)
        if (t_window + 80 > t_img) {
          p_search.addClass('fixed')
        } else {
          p_search.removeClass('fixed')
        }
      })
    }
  },
  search_slide() {
    let s_btn = $('.p-search-btn');
    let s_icon = s_btn.find('span i');

    s_btn.on('click', function (e) {
      e.preventDefault();

      let s_filter = $(this).parent('.p-search-filter');

      if (!s_filter.hasClass('s-hide')) {
        s_filter.addClass('s-hide');
        s_icon.removeClass().addClass('fa fa-arrow-up')
      } else {
        s_filter.removeClass('s-hide');
        s_icon.removeClass().addClass('fa fa-arrow-down')
      }
    })
  },
  section_view() {
    let btn = $('#p-data-link .item-link');
    let section = $('#p-data-content');

    btn.on('click', function (e) {
      e.preventDefault();

      let _this = $(this);
      let btn_data = $(this).data('p');
      let s_view = section.find('.' + btn_data);

      if (s_view.length) {
        section.find('> div').removeClass('active');
        btn.removeClass('active');

        s_view.addClass('active');
        _this.addClass('active')
      }
    })
  },
  filter_month_date(type) {
    let month = $(document).find('form#sr-product-form-' + type + ' .sr-select-month'),
      days = $(document).find('form#sr-product-form-' + type + ' .sr-select-day');

    if (days.length > 0 && month.length > 0) {
      let selected = month.find('option:selected').val();

      days.find('option[data-month]').removeAttr('selected');
      days.find('option[data-month]').attr('disabled', 'disabled');
      days.find('option[data-month]').addClass('d-none').removeClass('select');

      days.find('option[data-month="' + selected + '"]').removeAttr('disabled');
      days.find('option[data-month="' + selected + '"]').removeClass('d-none').addClass('select');
      days.find('option[data-month="' + selected + '"]').find(':first-child').first().attr('selected', 'selected');

      setTimeout(function () {
        days.find('option[data-month]:not([disabled])').first().attr('selected', 'selected');
        product.filter_day_date(selected, type);
        product.filter_price(type)
      }, 500)
    }
  },
  filter_day_date(the_month = null, type) {
    let month = $(document).find('form#sr-product-form-' + type + ' .sr-select-month'),
      week = $(document).find('form#sr-product-form-' + type + ' .sr-select-week'),
      day = $(document).find('form#sr-product-form-' + type + ' .sr-select-day'),
      all_days = [],
      _nb_week = 0;

    let the_day = day.find('option:selected').val();
    let selected_month = the_month !== null ? the_month : month.find('option:selected').val();

    // day.find('option:not([disabled])').each(function (i, el) {
    //     all_days.push($(this).val())
    // })
    // all_days = all_days.filter(util.onlyUniqueArray);

    day.find('option').each(function (i, el) {
      all_days.push($(this).val())
    });
    all_days = all_days.filter(util.onlyUniqueArray);

    _nb_week = product.get_week_nb(all_days, the_day);

    week.find('option').addClass('d-none').removeClass('select').attr('disabled', 'disabled');
    week.find('option').each(function (i, el) {
      if (_nb_week !== 0 && $(this).val() - 1 < _nb_week) {
        $(this).removeClass('d-none').addClass('select').removeAttr('disabled')
      }
    });

    week.find('option').removeAttr('selected');
    setTimeout(function () {
      week.find('option:not([disabled])').first().attr('selected', 'selected');
      product.filter_week_date(type);
      product.filter_price(type)
    }, 500)
  },
  filter_week_date(type) {
    let week = $(document).find('form#sr-product-form-' + type + ' .sr-select-week'),
      day = $(document).find('form#sr-product-form-' + type + ' .sr-select-day'),
      input_week_day = $(document).find('form#sr-product-form-' + type + ' .sr-week-day'),
      week_day = 7;

    if (typeof day !== 'undefined') {
      let selected_day = day.find('option:selected').val();
      if (typeof selected_day !== 'undefined') {
        week_day = day.find('option:selected').attr('data-week_day');
        input_week_day.val(week_day);
        selected_day = selected_day.toString()
      }
      let selected_week = parseInt(week.find('option:selected').val());

      setTimeout(function () {
        product.set_returned_date(selected_day, selected_week, week_day);
        product.filter_price(type)
      }, 500)
    }
  },
  filter_price(type) {
    let month = $(document).find('form#sr-product-form-' + type + ' .sr-select-month'),
      week = $(document).find('form#sr-product-form-' + type + ' .sr-select-week'),
      day = $(document).find('form#sr-product-form-' + type + ' .sr-select-day'),
      _periode = $(document).find('form#sr-product-form-' + type + ' .sr-select-periode'),
      periode = day.find('option:selected').attr('data-periode'),
      price = $(document).find('form#sr-product-form-' + type + ' .price'),
      input_price = $(document).find('form#sr-product-form-' + type + ' .sr-calc-price'),
      key = '',
      json_var = $('#json-product-price'),
      loading = $(document).find('.p-search__filter');

    _periode.val(periode);

    if (json_var.length > 0) {

      let json_prices = JSON.parse(json_var.html());
      let selected_week = parseInt(week.find('option:selected').val());

      key = selected_week + '-' + periode;

      if (typeof json_prices[key] !== 'undefined' && json_prices[key] !== null) {
        price.text(json_prices[key]['price'] + ' €');
        input_price.val(json_prices[key]['price']);
        // console.log('price for >', periode, day, week);
        loading.removeClass('load')
      } else {
        price.text('0 €');
        input_price.val('0');
        loading.removeClass('load')
      }
    }
  },
  change_filter_option() {

  },
  check_recurrent_week(date1, date2) {
    let d1 = moment(date1, 'YYYYMMDD'),
      d2 = moment(date2, 'YYYYMMDD'),
      d1_end = d1.endOf('isoWeek').clone().add(1, 'days'),
      d2_start = d2.startOf('isoWeek');

    let isSame = d1_end.isSame(d2_start, 'day');
    let diff = d2_start.diff(d1_end, 'day');

    // console.log(d1_end.toISOString(), d2_start.toISOString())
    // console.log(diff, d1_end.format("YYYY MM DD"), ' --- ', d2_start.format("YYYY MM DD"))

    return isSame
  },
  get_week_nb(all_days, the_day) {
    let _nb_week = 0,
      prev_day = null,
      recurrent_date = null;

    // console.log(all_days)

    all_days.forEach(function (el, i) {
      if (el === the_day || _nb_week !== 0) {
        if (prev_day === null) {
          _nb_week = 1;
          prev_day = el

          // console.log('no prev', _nb_week)
        } else {
          recurrent_date = product.check_recurrent_week(prev_day, el);
          if (recurrent_date) {
            _nb_week++
          } else {
            return _nb_week
          }
          // console.log("prev : ", prev_day, " next : ", el, "the_day", the_day)
          prev_day = el

          // console.log("recc : ", recurrent_date, "_nb_week : ", _nb_week)
        }
      }
    });

    return _nb_week
  },
  set_returned_date(the_day, nb_week, week_day) {
    let rdate = $(document).find('form .sr-select-rdate'),
      inpute_date = $(document).find('form .sr-return-date-input'),
      v_rdate = '',
      all_day = 0;

    // console.log('the_day >', the_day, nb_week)

    let m_day = moment(the_day, 'YYYYMMDD');
    nb_week = parseInt(nb_week);
    if (week_day < 7) {
      all_day = (7 * nb_week) - 1
    } else {
      all_day = 7 * nb_week
    }
    let retuned_date = m_day.clone().add(all_day, 'day').format('DD/MM/YYYY');
    v_rdate = 'Date de retour ' + retuned_date;
    rdate.val(v_rdate);
    inpute_date.val(retuned_date)
  },
  get_date() {
    let month = $(document).find('form .sr-select-month'),
      week = $(document).find('form .sr-select-week'),
      day = $(document).find('form .sr-select-day');

    if (month !== 'undefined' && month.length > 0) {
      month.on('change', function () {
        product.filter_month_date($(this).attr('data-type'))
      })
    }

    if (day !== 'undefined' && day.length > 0) {
      day.on('change', function () {
        product.filter_day_date(null, $(this).attr('data-type'))
      })
    }

    if (week !== 'undefined' && week.length > 0) {
      week.on('change', function () {
        product.filter_week_date($(this).attr('data-type'))
      })
    }
  }
};

/****** RESERVE JS *******/

let reserve = {
  legend() {
    let btn = $(document).find('.p-reserve .legend .legend__link');

    btn.on('click', function (e) {
      e.preventDefault();

      let btn_data = parseInt($(this).attr('data-stage'));

      if (btn_data) {
        reserve.goToStage(btn_data)
      }
    })
  },

  validateEach(elements, stage = 0) {
    let error = 0;

    if (elements.length > 0) {
      elements.each(function (i, el) {
        $(this).removeClass('error');
        $(this).parent().removeClass('error')
      });

      elements.each(function (i, el) {
        let _this = $(this);
        let value = _this.val();

        if (_this.attr('type') === 'checkbox') {
          if (!_this.is(':checked')) {
            _this.parent().addClass('error');
            error++
          }
        }

        if (value.length < 1) {
          _this.addClass('error');
          error++
        }
      });

      if (error > 0) {
        if (stage !== 0) {
          $(document).find('.stage-' + stage + ' .error-msg').addClass('view')
        } else {
          $(document).find('.stage .error-msg').addClass('view')
        }
        return false
      } else {
        console.log('all ok in this stage');
        return true
      }
    }
  },

  goToStage(stage) {
    if (stage !== 'undefined') {
      let legend = $(document).find('.p-reserve .legend .legend__link');
      let section = $(document).find('.p-reserve .stage');
      let next_legende = $(document).find('.p-reserve .legend .legend__link.btn-stage-' + stage);

      //section.fadeOut(400)
      section.removeClass('active');
      legend.parent('.legend__item').removeClass('active');

      next_legende.parent('.legend__item').addClass('active');
      //$(document).find(".p-reserve .stage-" + stage).addClass('active').fadeIn(800)
      $(document).find('.p-reserve .stage-' + stage).addClass('active');

      util.scrollToTop()
    }
  },

  check_form_info(form_data) {
    let form = $(document).find('#sj-reserve-form');
    let elm1 = form.find('.stage-3 .elm-1');
    let elm2 = form.find('.stage-3 .elm-2');
    let elm3 = form.find('.stage-3 .elm-3');
    let elm4 = form.find('.stage-3 .elm-4');
    let elm5 = form.find('.stage-3 .elm-5');
    let elm6 = form.find('.stage-3 .elm-6');
    let elm7 = form.find('.stage-3 .elm-7');
    let opts = $(document).find('.stage-1 .sjr-1-opt');

    function getRadioSelected(value) {
      if (value === 'no') {
        return 'Non'
      } else if (value === 'yes') {
        return 'Oui'
      } else if (value === 'other') {
        return 'Peu importe'
      }
    }

    if (form_data !== undefined) {
      let infos = [];

      form_data.forEach(function (el, i) {
        infos[el.name] = el.value
      });

      let opts_nb = 0;

      elm3.empty();
      opts.each(function (i, el) {
        if ($(this).is(':checked')) {
          let val = $(this).val();
          val = val.split('-');
          let _title = $(document).find('#sjr-1-opt-title-' + val[0]);
          let _unity = $(document).find('#sjr-1-opt-unity-' + val[0]);

          let title = _title.val();
          let unity = _unity.val();
          let value = parseInt(val[1]);

          elm3.append(title);
          opts_nb++
        }
      });

      if (opts_nb < 0) {
        elm3.empty();
        elm3.append('<p>Vous n\'avez pas sélectionné d\'options.</p>')
      }

      /* ELM 3 */
      // if (infos['sjr-1-assurance'] || infos['sjr-1-sum'] || infos['sjr-1-tsup']) {
      //     elm3.empty()
      //     if (infos['sjr-1-assurance']) {
      //         elm3.append('<p>Assurance annulation</p>')
      //     }
      //     if (infos['sjr-1-sum']) {
      //         elm3.append('<p>Sérvice UM (accompagnement des mineurs)</p>')
      //     }
      //     if (infos['sjr-1-tsup']) {
      //         elm3.append('<p>Transfert supplémentaire</p>')
      //     }
      // } else {
      //     elm3.empty()
      //     elm3.append("<p>Vous n'avez pas sélectionné d'options.</p>")
      // }

      /* ELM 4 */
      if (infos['sjr-2-fname']) {
        elm4.empty();
        elm4.append(' <p>Civilité : ' + infos['sjr-2-civility'] + ' ' + infos['sjr-2-fname'] + ' ' + infos['sjr-2-name'] + '</p>\n' +
          '<p>Date de naissance : ' + infos['sjr-2-ndate'] + '</p>\n' +
          '<p>Langue maternelle : ' + infos['sjr-2-lm'] + '</p>\n' +
          '<p>Niveau de langue : ' + infos['sjr-2-nl'] + '</p>\n' +
          '<p>Adresse : ' + infos['sjr-2-address'] + ' ' + infos['sjr-2-caddress'] + '</p>\n' +
          '<p>Code postal : ' + infos['sjr-2-cp'] + '</p>\n' +
          '<p>Ville : ' + infos['sjr-2-ville'] + '</p>\n' +
          '<p>Pays : ' + infos['sjr-2-country'] + '</p>')
      }

      /* ELM 5 */
      if (infos['sjr-2-familyc']) {
        elm5.empty();
        elm5.append('<p>Famille avec enfant : ' + getRadioSelected(infos['sjr-2-familyc']) + '</p>\n' +
          '<p>Famille non fumeur : ' + getRadioSelected(infos['sjr-2-familyf']) + '</p>\n' +
          '<p>Famille avec des animaux : ' + getRadioSelected(infos['sjr-2-familya']) + '</p>\n' +
          '<p>Je suis végétarien : ' + getRadioSelected(infos['sjr-2-vegetarien']) + '</p>');

        if (infos['sjr-2-allergie']) {
          elm5.append('<p>Allergies/Traitement médical : ' + infos['sjr-2-allergie'] + '</p>')
        }
        if (infos['sjr-2-regime']) {
          elm5.append('<p>Régime alimentaire spécial : ' + infos['sjr-2-regime'] + '</p>')
        }
      }

      /* ELM 6 */
      if (infos['sjr-2-uname']) {
        elm6.empty();
        elm6.append('<p>Nom : ' + infos['sjr-2-uname'] + '</p>\n' +
          '<p>Prénom : ' + infos['sjr-2-ufname'] + '</p>\n' +
          '<p>Email : ' + infos['sjr-2-uemail'] + '</p>\n' +
          '<p>Téléphone portable : ' + infos['sjr-2-utelp'] + '</p>');

        if (infos['sjr-2-utelf']) {
          elm6.append('<p>Téléphone fixe : ' + infos['sjr-2-utelf'] + '</p>')
        }
      }

      /* ELM 7 */
      if (infos['sjr-2-sjconnu']) {
        elm7.empty();
        elm7.append('<p>' + infos['sjr-2-sjconnu'] + '</p>')
      }
    }

  },

  stage() {
    let legend = $(document).find('.p-reserve .legend .legend__link');
    let section = $(document).find('.p-reserve .stage');
    let form = $(document).find('#sj-reserve-form');

    let btn_1 = $(document).find('#sjr-1-btn');
    let btn_2 = $(document).find('#sjr-2-btn');
    let btn_3 = $(document).find('#sjr-3-btn');
    let btn_3_2 = $(document).find('#sjr-3-btn-2');
    let btn_4 = $(document).find('#sjr-4-btn');

    btn_1.on('click', function (e) {
      e.preventDefault();

      reserve.goToStage(2)
    });

    btn_2.on('click', function (e) {
      e.preventDefault();
      let field = $(document).find('.stage-2 .required');

      $(document).find('.stage-2 .error-msg').removeClass('view');

      if (field.length > 0) {
        let valid = reserve.validateEach(field, 2);

        if (valid) {
          let form_data = form.serializeArray();

          reserve.check_form_info(form_data);
          reserve.goToStage(3)
        }
      }
    });

    btn_3.on('click', function (e) {
      e.preventDefault();

      reserve.goToStage(4)
    });

    btn_3_2.on('click', function (e) {
      e.preventDefault();

      reserve.goToStage(1)
    })

    // btn_4.on('click', function (e) {
    //     e.preventDefault()
    //
    //     let field = $(document).find('.stage-4 .required')
    //
    //     $(document).find('.stage-4 .error-msg').removeClass('view')
    //
    //     if (field.length > 0) {
    //         let valid = reserve.validateEach(field, 4)
    //
    //         if (valid) {
    //             reserve.goToStage(5)
    //         }
    //     }
    // })
  },
  selected_form() {
    let form = $(document).find('#sj-reserve-form'),
      init_price = form.find('.sj-init-price').val(),
      asur = $(document).find('#sjr-1-assurance'),
      um = $(document).find('#sjr-1-sum'),
      asur_price = 0,
      um_price = 0,
      total_price = init_price;

    if (asur.length > 0) {
      asur_price = asur.val();

      asur.on('change', function () {
        // console.log($(document).find('#sjr-1-assurance:selected'));
        if ($(document).find('#sjr-1-assurance:selected')) {
          total_price = total_price + asur_price
        }
      })
    }
    if (um.length > 0) {
      um_price = um.val();
      um.on('change', function () {
        // console.log($(document).find('#sjr-1-sum:selected'));
        if ($(document).find('#sjr-1-sum:selected')) {
          total_price = total_price + um_price
        }
      })
    }

    // console.log('total_price : ', total_price)
  }
};

/****** SLIDER JS *******/

let slider = {
  price() {
    let el = document.getElementById('slide-price');
    let p_min = $(document).find('#sj-search-min-price');
    let p_max = $(document).find('#sj-search-max-price');

    if (el) {
      noUiSlider.create(el, {
        start: [300, 4000],
        tooltips: true,
        step: 5,
        behaviour: 'drag-tap',
        connect: true,
        range: {
          'min': [50],
          'max': [10000]
        },
        format: wNumb({
          decimals: 0,
          thousand: ' ',
          suffix: ' €',
        })
      });

      el.noUiSlider.on('change', function () {
        let price = el.noUiSlider.get();
        // console.log(price);

        p_min.val(price[0].replace(' €', '').replace(' ', ''));
        p_max.val(price[1].replace(' €', '').replace(' ', ''))
      })
    }
  },
  other_dest() {
    let btn = $('#sj-other-dest');
    let block = $('.h-dest.other-dest');

    btn.on('click', function (e) {
      e.preventDefault();

      if (block.hasClass('slide-h')) {
        block.removeClass('slide-h')
      } else {
        block.addClass('slide-h')
      }
    })
  }
};

/****** MATCH HEIGHT *******/

let matchHeight = {
  init() {
    $(function () {
        $(document).find('.m-ht').matchHeight();
        $(document).find('.m-ht-1').matchHeight();
        $(document).find('.m-ht-2').matchHeight();
        $(document).find('.m-ht-3').matchHeight();
        $(document).find('.m-ht-4').matchHeight();
        $(document).find('.m-ht-5').matchHeight()
      }
    )
  }
};

/**
 * Masonry js
 */
let grid_masonry = {
  load() {
    let grid = document.querySelector('.grid-masonry');
    if (grid) {
      let msnry = new Masonry(grid, {
        // options...
        itemSelector: '.masonry-item',
        columnWidth: '.item',
        percentPosition: true
      });
      return msnry
    }
  },
  init() {
    let msnry = grid_masonry.load();

    if (msnry) {
      if ($(window).width() < 576) {
        msnry.destroy();
        $(document).find('.masonry-item').attr('style', '')
      }

      $(window).on('resize', function (event) {
        let $window = $(window);
        if ($window.width() < 576) {
          msnry.destroy();
          $(document).find('.masonry-item').attr('style', '')
        } else {
          grid_masonry.load()
        }
      })
    }
  }
};
// import Js Plugins/Entities

//ES6 Module
//import Bar1 from './entities/Bar1';

//CommonJS
//let Bar2 = require('./entities/Bar2');

window.h5 = {
  isPc: function () {
    var userAgentInfo = navigator.userAgent;
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break
      }
    }
    return flag
  },
  rootResize: function () {
    var wFsize;

    //iphone6/6s/7/8 orientation=portrait screen.width=750px screen.height=1334px / WeChat window.innerWidth=750px window.innerHeight=1206px
    var wWidth = (window.screen.width > 0) ? (window.innerWidth >= window.screen.width || window.innerWidth == 0) ? screen.width :
      window.innerWidth : window.innerWidth;
    var wHeight = (window.screen.height > 0) ? (window.innerHeight >= window.screen.height || window.innerHeight == 0) ?
      window.screen.height : window.innerHeight : window.innerHeight;
    // var wWidth = window.innerWidth;
    // var wHeight = window.innerHeight;

    if (wWidth > wHeight) {
      wFsize = wHeight / 750 * 100
    } else {
      wFsize = wWidth / 750 * 100
    }
    document.getElementsByTagName('html')[0].style.fontSize = wFsize + 'px'
  },
  eventInit: function () {
    var that = this;
    document.addEventListener('touchstart', function (e) {
    }, {passive: false});
    document.addEventListener('touchmove', function (e) {
      e.preventDefault()
    }, {passive: false});
    return that
  },
  cssInit: function () {
    var that = this;
    var noChangeCountToEnd = 100,
      noEndTimeout = 1000;
    that.rootResize();
    window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', function () {
      var interval,
        timeout,
        end,
        lastInnerWidth,
        lastInnerHeight,
        noChangeCount;
      end = function () {
        // "orientationchangeend"
        clearInterval(interval);
        clearTimeout(timeout);
        interval = null;
        timeout = null;
        that.rootResize()
      };
      interval = setInterval(function () {
        if (window.innerWidth === lastInnerWidth && window.innerHeight === lastInnerHeight) {
          noChangeCount++;
          if (noChangeCount === noChangeCountToEnd) {
            // The interval resolved the issue first.
            end()
          }
        } else {
          lastInnerWidth = window.innerWidth;
          lastInnerHeight = window.innerHeight;
          noChangeCount = 0
        }
      });
      timeout = setTimeout(function () {
        // The timeout happened first.
        end()
      }, noEndTimeout)
    });

    return that
  },
  init: function () {
    var that = this;
    that.cssInit().eventInit()
  }
};

//Stats JavaScript Performance Monitor

//import Stats from 'stats.js';
//showStats();
// function showStats() {
//     var stats = new Stats();
//     stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
//     var fs = document.createElement('div');
//     fs.style.position = 'absolute';
//     fs.style.left = 0;
//     fs.style.top = 0;
//     fs.style.zIndex = 999;
//     fs.appendChild(stats.domElement);
//     document.body.appendChild(fs);

//     function animate() {
//         stats.begin();
//         // monitored code goes here
//         stats.end();
//         requestAnimationFrame(animate);
//     }
//     requestAnimationFrame(animate);
// }
